.privacy{
    margin-top: 7rem;
   
}
.privacy-content{
    
    margin:1rem 5rem;
   
}
.policy_titles{
    text-decoration: underline;
    color:darkblue;
    margin: 1rem;
    font-size:22px;
    text-align: center;
}
.policy_subtitles{
    font-size:18px;
    text-align: left; 
    margin: 1rem 0px;
    color:darkblue;
}
.policy_minititles{
    
    text-align: left; 
    margin: 1rem 0px;
    
}
.defination_list{
    text-align: left;
    margin-left: 2rem;
}
.list_item{
    margin-bottom: 10px;
}
@media only screen and (max-width:1200px) {
    .privacy{
        margin-top: 6rem;
        margin-right: 20px;
    }
    .privacy-content{
        
        margin:2rem 1rem;
       
    }
    .policy_titles{
        text-decoration: underline;
        color:darkblue;
        margin: 1rem;
        font-size:22px;
        text-align: center;
    }
    .policy_subtitles{
        font-size:18px;
        text-align: left; 
        margin: 1rem 0px;
        color:darkblue;
    }
    .policy_minititles{
        
        text-align: left; 
        margin: 1rem 0px;
        
    }
    .defination_list{
        text-align: left;
        margin-left: 1rem;
    }
    .list_item{
        margin-bottom: 10px;
    }
    .privacy_text{
        margin-bottom: 10px;
    }
}