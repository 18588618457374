.main{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:5rem;
    width: 95vw;
    height: 75vh;
}
.main_left{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:5rem;
    width: 60vw;
    
}
.left {
    width: 15vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-weight: bold;
    font-family: cursive;
    color: red;
    margin-left: 1rem;
}
.image{
    border-radius: 20px;
    
}
.middle {
    width: 30vw;
    font-size: 32px;
    font-weight: bold;
    font-family: cursive;
    color: red;
    margin-left: 1rem;
    margin-top: 1rem;
    animation:zoomIn;
    animation-duration: 2s; 
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.right {
    width: 15vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-weight: bold;
    font-family: cursive;
    color: red;
    margin-left: 1rem;
   
}
.main_right{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:2rem;
    width: 35vw;
  padding: 5px;
}
.image1{
    width: 14vw;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 2px 3px 3px;
    rotate: 15deg;
    animation:fadeInUp;
    animation-duration: 2s; 
}
.image2{
    width: 14vw;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 2px 3px 3px;
    animation:fadeInDown;
    animation-duration: 2s; 
}
@media only screen and (max-width:1200px) {
    .main{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 99vw;
        height: fit-content;
    }
    .main_left{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
     
        width: 90vw;
      
    }
    .left {
        width: 85vw;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        font-weight: bold;
        font-family: cursive;
        color: red;
        margin-top: 1rem;
      
    }
    .image{
        border-radius: 15px;
        
    }
    .middle {
        width: 90vw;
        font-size: 32px;
        font-weight: bold;
        font-family: cursive;
        color: red;
        margin-bottom: 1rem;
        
        border-radius: 20px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    .right {
        width: 85vw;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        font-weight: bold;
        font-family: cursive;
        color: red;
       
       
    } 
    .main_right{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top:3rem;
        margin-left:0.8rem;
        width: 95vw;
      padding: 5px;
    }
    .image1{
        width: 40vw;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 2px 3px 3px;
        rotate: 1deg;
    }
    .image2{
        width: 40vw;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 2px 3px 3px;
       
    }
}