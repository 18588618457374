.nav_bar{
    background-color: black;
    margin-top: -10px;
    height: 4.5rem;
}

.links{
    font-weight: bold;
  }
  .buttons{
    align-content:flex-end;
    justify-content: right;
  }
  .top{
   width: 90vw;
   display: flex;
   justify-content: center;
    margin-top: 5rem;
  }
  .title{
    font-size: 32px;
    font-weight: bold;
    font-family:cursive;
    color:black;
    margin-left: 1rem;
  }