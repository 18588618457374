.footer {
    display: flex;
    background: rgb(252, 251, 248);
    background: linear-gradient(360deg, rgba(219, 166, 247, 1) 3%, rgba(252, 251, 248, 1) 100%);
    justify-content: space-between;

}
.li {
    font-size: 18px;
    margin-right: 10px;
    text-decoration: none;
    color:black;
}
.li :hover{
    text-decoration: underline;
    color:blue;
}
.socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 50px;
}
.list-divs{
   margin: 10px;
}
@media only screen and (max-width:1200px) {
    .footer {
        display: flex;
        flex-direction: column;
        background: rgb(252, 251, 248);
        background: linear-gradient(360deg, rgba(219, 166, 247, 1) 3%, rgba(252, 251, 248, 1) 100%);
        align-items: center;
    
    }
    .list-divs{
        text-align: center;
    }
    .li {
        font-size: 16px;
        margin-right: 10px;
        text-decoration: none;
        color:black;
        margin-top: 10px;
    }
    .socials{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 10px;
        margin-bottom: 10px;
    }
}
