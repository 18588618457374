.error-404 {
   
    
    height: 80vh;
    width: 90vw;
}
.image-404{
    margin-left: 35vw;
    margin-top: 5rem;
    height: 300px;
    width: 300px;
}
@media only screen and (max-width:1200px) {
    .error-404 {
   
        height: 80vh;
        width: 90vw;
    }
    .image-404{
        margin-left: 10vw;
        margin-top: 8rem;
       height: 300px;
        width: 300px;
    }
}